


















































































































































































































































































































































































































































































































































































import { Component, Watch } from 'vue-property-decorator';
import Vue from 'vue';
import { mixins } from 'vue-class-component';
import TasqDetailsMixin from '@/components/tasqsCommon/TasqDetailsMixin';
import tasqsListModule from '@/store/modules/tasqsListModule';
import tasqSignalsModule from '@/store/modules/tasqSignalsModule';
import { getComponent } from '@/utils/helpers';
import tasqProductionDataChartModule from '@/store/modules/tasqProductionDataChartModule';
import DataLoading from '@/lib/mixins/dataLoading';
import assetsModule from '@/store/modules/assetsModule';
import { Debounce } from 'vue-debounce-decorator';
import { getNameByEmail } from '@/utils/users';
import { TASQ_OFF_TARGET_TYPE, WELL_CLICK_EVENT } from '@/lib/constants';
import TasqLightningChart from './TasqLightningChart.vue';
import TasqLightningProductionChart from './TasqLightningProductionChart.vue';
import TasqPlungerLightningChart from './TasqPlungerLightningChart.vue';
import TasqJob from '@/interfaces/tasqs/TasqJob';

@Component({
  components: {

    TasqLoader: () => getComponent('loaders/TasqLoader'),
    eventDetail: () => getComponent('tasqs/TasqEventSummaryPopup'),
    TasqLightningChart: () => getComponent('tasqs/TasqLightningChart'),
    TasqPlungerLightningChart: () => getComponent('tasqs/TasqPlungerLightningChart'),
    TasqLightningProductionChart: () => getComponent('tasqs/TasqLightningProductionChart'),
  },
})
export default class
WellSignalsView extends mixins(DataLoading) {
  chartTimes: any[] = this.$getConst('CHART_TIMES');

  signalChartTime = 60;

  chartRefreshKey = 0

legendRefreshKey = 0

legendRefreshPlungerKey = 0

legendRefreshKeyProd = 0

showFullScreenSignalsChart = false

showFullScreenPlungerSignalsChart = false;

showFullScreenProductionChart = false

isLoadingSignals = true

showChartModal: any = false;

isLoadingPlungerSignals = true;

isLoadingProductionData = true

failedToPullPlungerData = false;

showWellEventsLocal = false;

localHistoryEvent = true;

failedToPullProductionData = false

chartsLoading = false;

customSignalRange: any = { startDate: null, endDate: null }

wellEventDetails: any = {
	  action: {},
	  createdBy: '',
	  createdDate: '',
  comment: '',
  jobType: '',
}

  wellEventDetailsDefault: any = {
    action: {},
    createdBy: '',
    createdDate: '',
    comment: '',
    jobType: '',
  }

  showWellEventDetail = false;

  get selectedCustomRange() {
    // console.log(this.customSignalRange);
    if (this.customSignalRange.startDate && this.customSignalRange.endDate) {
      return true;
    }
    return false;
  }

  get tasqListLevel() {
	  if (tasqsListModule.tasqListLevel.toLowerCase() == 'well' || (this.activeTasq && tasqsListModule.tasqListLevel.toLowerCase() !== 'wellview' && this.activeTasq.level.toLowerCase() == 'well')) {
		  return 'Well';
	  }
	  return tasqsListModule.tasqListLevel.toLowerCase();
    //   if (this.activeTasq == null) {
    // 	  return 'WELL';
    //   }
    //   return this.activeTasq?.level;
  }

  get isHistorySectionOpen() {
    return tasqsListModule.isHistorySectionOpen;
  }

  get activeTasq() {
    if (tasqsListModule.tasqListLevel.toLowerCase() === 'workticket') {
      console.log(tasqsListModule.activeWorkTicket);
      return tasqsListModule.activeWorkTicket;
    }
    if (this.isEditing || tasqsListModule.checkedTasqs.length) {
		  if (!tasqsListModule.activeTasq) {
        tasqsListModule.setActiveTasq(tasqsListModule.checkedTasqs[0]);
      }
		  return tasqsListModule.activeTasq as TasqJob;
	  }
    if (this.$route.query.type == 'id' && this.$route.params.id != null) {
      assetsModule.setActiveProducingTasq('');
      return tasqsListModule.activeTasq as TasqJob;
    }
    if (tasqsListModule.activeTasq != null && this.$route.params.id != null && this.$route.query.type == 'producing' && tasqsListModule.activeTasq.level.toLowerCase() == 'pad') {
	  return tasqsListModule.activeTasq;
    }
    if (assetsModule.activeTasq == undefined) {
      return tasqsListModule.activeTasq as TasqJob;
    }
    return assetsModule.activeTasq as TasqJob;
  }

  get showWellEvents() {
    return tasqProductionDataChartModule.showWellEvents;
  }

  get isEditing() {
	  return tasqsListModule.isEditing;
  }

  get didPlungerSignalsLoaded() {
    return tasqSignalsModule.didLoadAllPlungerSignals;
  }

  get productionData() {
    return tasqProductionDataChartModule.productionData;
  }

  get dateRangeCustomPreset() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return {
      'Last month': [new Date(today.getFullYear(), today.getMonth() - 1, today.getDate()), new Date(today.getFullYear(), today.getMonth(), today.getDate())],
      'Last 3 months': [new Date(today.getFullYear(), today.getMonth() - 3, today.getDate()), new Date(today.getFullYear(), today.getMonth(), today.getDate())],
      'Last 6 months': [new Date(today.getFullYear(), today.getMonth() - 6, today.getDate()), new Date(today.getFullYear(), today.getMonth(), today.getDate())],
      'Last Year': [new Date(today.getFullYear(), today.getMonth() - 12, today.getDate()), new Date(today.getFullYear(), today.getMonth(), today.getDate())],
    };
  }

  didSelectResetChart(type) {
    if (type == 'tasq-lightning-chart') {
      (this.$refs.myChildRef as TasqLightningChart).didSelectResetChart();
    } else if (type == 'tasq-lightning-chart-full') {
      (this.$refs.myChildRefFull as TasqLightningChart).didSelectResetChart();
    } else if (type == 'tasq-lightning-chart-prod') {
      (this.$refs.myChildRefProd as TasqLightningProductionChart).didSelectResetChart();
    } else if (type == 'tasq-lightning-chart-prod-full') {
      (this.$refs.myChildRefProdFull as TasqLightningProductionChart).didSelectResetChart();
    } else if (type == 'tasq-plunger-lightning-chart') {
      (this.$refs.myPlungerChartRef as TasqLightningChart).didSelectResetChart();
    } else if (type == 'tasq-plunger-lightning-chart-full') {
      (this.$refs.plungerChartRefFull as TasqLightningChart).didSelectResetChart();
    }
  }

  isSignalSelected(signal_name) {
    return (tasqSignalsModule.selectedSignals.indexOf(signal_name) > -1);
  }

  isPlungerSignalSelected(signal_name) {
    return (tasqSignalsModule.selectedPlungerSignals.indexOf(signal_name) > -1);
  }

  isSignalSelectedProd(signal_name) {
    return (tasqProductionDataChartModule.selectedSignals.indexOf(signal_name) > -1);
  }

  getCurrentSignalNameForLegend(signal_name) {
    if (signal_name == 'gas_rate') {
      return 'Gas Rate';
    } if (signal_name == 'oil_rate') {
      return 'Oil Rate';
    } if (signal_name == 'water_rate') {
      return 'Water Rate';
    }
    return signal_name;
  }

  updateChartToMatchLegend() {
    if (this.showFullScreenSignalsChart) {
    } else {
      (this.$refs.myChildRef as TasqLightningChart).updateChartToMatchLegend();
      console.log('Doing a full reset for main page hopefully');
    }
  }

  updatePlungerChartToMatchLegend() {
    if (this.showFullScreenSignalsChart) {
    } else {
      (this.$refs.myPlungerChartRef as TasqLightningChart).updateChartToMatchLegend();
      console.log('Doing a full reset for main page hopefully');
    }
  }

  didSelectSignalOnLegendProd(index, signal) {
    if (!this.showFullScreenProductionChart) {
      (this.$refs.myChildRefProd as TasqLightningProductionChart).initializePage(index, signal);
      tasqProductionDataChartModule.selectSignal(signal);
      this.legendRefreshKeyProd += 1;
    } else {
      (this.$refs.myChildRefProdFull as TasqLightningProductionChart).initializePage(index, signal);
      tasqProductionDataChartModule.selectSignal(signal);
      this.legendRefreshKeyProd += 1;
    }
  }

  didSelectSignalOnLegend(index, signal) {
    if (!this.showFullScreenSignalsChart) {
      (this.$refs.myChildRef as TasqLightningChart).initializePage(index, signal);
      tasqSignalsModule.selectSignal(signal);
      this.legendRefreshKey += 1;
    } else {
      (this.$refs.myChildRefFull as TasqLightningChart).initializePage(index, signal);
      tasqSignalsModule.selectSignal(signal);
      this.legendRefreshKey += 1;
    }
  }

  didSelectPlungerSignalOnLegend(index, signal) {
    if (!this.showFullScreenPlungerSignalsChart) {
      (this.$refs.myPlungerChartRef as TasqLightningChart).initializePage(index, signal);
      tasqSignalsModule.selectPlungerSignal(signal);
      this.legendRefreshPlungerKey += 1;
    } else {
      (this.$refs.plungerChartRefFull as TasqLightningChart).initializePage(index, signal);
      tasqSignalsModule.selectPlungerSignal(signal);
      this.legendRefreshPlungerKey += 1;
    }
  }

  didSelectProductionFullScreen() {
    this.showFullScreenProductionChart = !this.showFullScreenProductionChart;
  }

  didSelectSignalsFullScreen() {
    this.showFullScreenSignalsChart = !this.showFullScreenSignalsChart;
    this.updateChartToMatchLegend();
  }

  didSelectPlungerSignalsFullScreen() {
    this.showFullScreenPlungerSignalsChart = !this.showFullScreenPlungerSignalsChart;
    this.updateChartToMatchLegend();
  }

  addTasqDidSelectAssetTasq() {
  }

  get isProductionDataLoaded() {
    return tasqProductionDataChartModule.productionData.loaded;
  }

  get currentWellType(): any {
	  return this.$route.query.type;
  }

  get signalDescriptions(): any {
	  return tasqSignalsModule.signalDescriptions;
  }

  get plungerSignalDescriptions(): any {
	  return tasqSignalsModule.plungerSignalDescriptions;
  }

  get currentSignals(): any {
    return tasqSignalsModule.currentSignals;
  }

  get currentPlungerSignals(): any {
    return tasqSignalsModule.currentPlungerSignals;
  }

  get productionDataDict(): any {
    return tasqProductionDataChartModule.productionDataDict;
  }

  get legendSignalsProd(): any {
    const legend_signals:any = [];

    for (const [key, value] of Object.entries(this.productionDataDict)) {
      if (key == 'date' || value == null || value == undefined || key == 'nodeid') {
        continue;
      }

      if (key != 'water_rate' && key != 'gas_rate' && key != 'oil_rate') {
        continue;
      }
      let color = '';
      if (key == 'water_rate') {
        color = '#0077ff';
      } else if (key == 'gas_rate') {
        color = '#f55d8b';
      } else if (key == 'oil_rate') {
        color = '#2de6c1';
      }
      legend_signals.push({
        name: key,
        selected: this.isSignalSelected(key),
        color,
        type: 'PRODUCTION',
      });
    }
    return legend_signals;
  }

  get legendSignals(): any {
    const legend_signals:any = [];
    for (let x = 0; x < this.currentSignals.length; x++) {
      legend_signals.push({
        name: this.currentSignals[x].name,
        selected: this.isSignalSelected(this.currentSignals[x].name),
        color: this.currentSignals[x].color,
        type: 'SIGNAL',
      });
    }

    for (const [key, value] of Object.entries(this.productionDataDict)) {
    // @ts-ignore
      if ((key != 'water_rate' && key != 'gas_rate' && key != 'oil_rate') || (value == undefined || value.length == 0)) {
        continue;
      }
      let color = '';
      if (key == 'water_rate') {
        color = '#0077ff';
      } else if (key == 'gas_rate') {
        color = '#f55d8b';
      } else if (key == 'oil_rate') {
        color = '#2de6c1';
      }
      legend_signals.push({
        name: key,
        selected: this.isSignalSelected(key),
        color,
        type: 'PRODUCTION',
      });
    }
    return legend_signals;
  }

  get legendPlungerSignals(): any {
    const legend_signals:any = [];
    for (let x = 0; x < this.currentPlungerSignals.length; x++) {
      legend_signals.push({
        name: this.currentPlungerSignals[x].name,
        selected: this.isPlungerSignalSelected(this.currentPlungerSignals[x].name),
        color: this.currentPlungerSignals[x].color,
        type: 'PLUNGER',
      });
    }

    for (const [key, value] of Object.entries(this.productionDataDict)) {
    // @ts-ignore
      if ((key != 'water_rate' && key != 'gas_rate' && key != 'oil_rate') || (value == undefined || value.length == 0)) {
        continue;
      }
      let color = '';
      if (key == 'water_rate') {
        color = '#0077ff';
      } else if (key == 'gas_rate') {
        color = '#f55d8b';
      } else if (key == 'oil_rate') {
        color = '#2de6c1';
      }
      legend_signals.push({
        name: key,
        selected: this.isSignalSelected(key),
        color,
        type: 'PRODUCTION',
      });
    }

    return legend_signals;
  }

  async customDateUpdated(data) {
    let fromNowBack = 0;
    const dateNow: any = new Date();
    const date1 = data.startDate;
    const date2 = data.endDate;
    if (Math.ceil(Math.abs(date2)) < Math.ceil(Math.abs(dateNow))) {
      fromNowBack = Math.ceil(Math.abs(date2 - dateNow) / (1000 * 60 * 60 * 24));
    }
    tasqsListModule.setFromNowBack(fromNowBack);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    await this.changeChartTimes(diffDays);
  }

  async changeChartTimes(val) {
    this.isLoadingSignals = true;
    this.isLoadingPlungerSignals = true;
    this.signalChartTime = val;
    tasqsListModule.setSignalChartTime(val);
    const promises: any[] = [];
    promises.push(tasqSignalsModule.getSignalsForTasq(val));
    Promise.all(promises).then(() => {
    //   this.chartsLoading = false;
	  this.chartRefreshKey += 1;
	  this.isLoadingSignals = false;
    }, (err) => {
      // error occurred
    //   this.chartsLoading = false;
    });

    const promisesPlungerSignals: any[] = [];
    promisesPlungerSignals.push(tasqSignalsModule.getPlungerSignalsForTasq(val));
    Promise.all(promisesPlungerSignals).then(() => {
    //   this.chartsLoading = false;
	  this.chartRefreshKey += 1;
      this.isLoadingPlungerSignals = false;
    }, (err) => {
      // error occurred
    //   this.chartsLoading = false;
    });

    const production_promises: any[] = [];
    this.isLoadingProductionData = true;
    this.failedToPullProductionData = false;
    production_promises.push(tasqProductionDataChartModule.getProductionData(this.signalChartTime));
    Promise.all(production_promises).then(async () => {
      this.isLoadingProductionData = false;
      this.failedToPullProductionData = false;
      // let t =  Math.floor(new Date().getTime()) - t;
      // console.log(new Date().getTime());
      // console.error(t);
		    // this.isLoadingSignals = false
      this.chartsLoading = true;
      this.$nextTick(() => {
        this.chartRefreshKey += 1;
        this.chartsLoading = false;
      });
    }, (err) => {
      console.log('Error: Failed to pull production data');
      console.log(err);
      this.failedToPullProductionData = true;
    });

    // const production_promises: any[] = [];
    // this.isLoadingProductionData = true
    // production_promises.push(tasqProductionDataChartModule.getWellHistory(val));
    // production_promises.push(defermentLabelingModule.getChartLabelingData(this.signalChartTime));
    // Promise.all(production_promises).then(() => {
    //   this.isLoadingProductionData = false
    //   this.chartsLoading = false;
    //   this.chartRefreshKey += 1
    // //   this.isLoadingSignals = false
    // }, (err) => {
    //   // error occurred
    //   this.chartsLoading = false;
    // });
  }

  @Debounce(1000)
  showEventCommentPopup(comment) {
    console.log(comment);
    if (comment) {
      this.wellEventDetails = {

        createdBy: comment.Username ? getNameByEmail(comment.Username) : 'N/A',
        createdDate: comment.time,
        ...comment,
        jobType: comment.JobType || comment.PredictionType,
      };
      this.showWellEventDetail = true;
    }
  }

  async fetchWellSignals() {
    this.wellEventDetails = this.wellEventDetailsDefault;
    this.chartTimes = this.$getConst('CHART_TIMES');
    this.failedToPullPlungerData = false;
    this.isLoadingPlungerSignals = true;
    tasqSignalsModule.setPlungerSignalDescriptions([]);
    this.signalChartTime = 60;
    tasqsListModule.setFromNowBack(0);
    tasqSignalsModule.resetSignals();
    tasqSignalsModule.resetPlungerSignals();
    tasqProductionDataChartModule.resetProductionData();
    this.customSignalRange = { startDate: null, endDate: null };
    if (this.activeTasq?.engineerType === TASQ_OFF_TARGET_TYPE) {
      let jobOriginExists = false;
      for (let t = 0; t < this.chartTimes.length; t++) {
        if (this.chartTimes[t].text == 'Job Origin') {
          jobOriginExists = true;
        }
      }
      if (!jobOriginExists) {
        this.chartTimes = [...this.chartTimes];
        this.signalChartTime = 60;
      }
    }
    tasqsListModule.setFromNowBack(0);
    tasqProductionDataChartModule.resetProductionDataDefermentLabeling();
    tasqsListModule.setSignalChartTime(this.signalChartTime);

    this.chartsLoading = false;

    const plungerSignalPromises: any[] = [];
    plungerSignalPromises.push(tasqSignalsModule.getPlungerSignalsForTasq(this.signalChartTime));
    Promise.all(plungerSignalPromises).then(async () => {
		    this.isLoadingPlungerSignals = false;
    }).catch((err) => {
      console.log(err);
      // this.failedToPullPlungerData = (true);
      this.$nextTick(() => {
        this.isLoadingPlungerSignals = false;
      });
    });

    const production_promises: any[] = [];
    this.isLoadingProductionData = true;
    this.failedToPullProductionData = false;
    production_promises.push(tasqProductionDataChartModule.getProductionData(this.signalChartTime));
    Promise.all(production_promises).then(async () => {
      this.isLoadingProductionData = false;
      this.failedToPullProductionData = false;

      // let t =  Math.floor(new Date().getTime()) - t;
      // console.log(new Date().getTime());
      // console.error(t);
		    // this.isLoadingSignals = false
      tasqsListModule.setIsInitialPageLoad(false);
      this.chartsLoading = true;
      this.$nextTick(() => {
        this.chartsLoading = false;
      });
    }, (err) => {
      // console.log('Error: Failed to pull production data');
      console.error(`Error: Failed to pull production data on ${this.activeTasq ? this.activeTasq.wellName : ''}, for ${this.signalChartTime} days`);
      console.log(err);
      this.failedToPullProductionData = true;
    });

    this.isLoadingSignals = true;
    const signalPromises: any[] = [];
    signalPromises.push(tasqSignalsModule.getSignalsForTasq(this.signalChartTime));
    Promise.all(signalPromises).then(async () => {
		    this.isLoadingSignals = false;
    }, (err) => {
      console.error(`Error: Failed to signal  data on ${this.activeTasq ? this.activeTasq.wellName : ''}, for ${this.signalChartTime} days`);
      console.log('Error:');
      console.log(err);
    });
  }




  timer;

  handleScroll() {
    if ((this.$refs.myChildRef as TasqLightningChart) != null) {
      (this.$refs.myChildRef as TasqLightningChart).reloadChartOnScroll();
    }


    if ((this.$refs.myChildRefProd as TasqLightningProductionChart) != null) {
      (this.$refs.myChildRefProd as TasqLightningProductionChart).reloadChartOnScroll();
    }
    
    if ((this.$refs.myPlungerChartRef as TasqPlungerLightningChart) != null) {
      (this.$refs.myPlungerChartRef as TasqPlungerLightningChart).reloadChartOnScroll();
    }
    
    
    

      if(this.timer !== null) {
          clearTimeout(this.timer);        
      }
      this.timer = setTimeout(function() {
            //  User stopped scrolling - we can handle workarounds here if need be
      }, 150);

  }




  async created() {
    tasqsListModule.setIsInitialPageLoad(true);
    this.isLoadingSignals = true;
    this.failedToPullProductionData = false;
    this.failedToPullPlungerData = false;

    this.$eventBus.$on(WELL_CLICK_EVENT, (comment) => {
      this.showEventCommentPopup(comment);
    });

    this.$eventBus.$on('history-search-updated', (history) => {
      this.localHistoryEvent = false;

	    const filteredHistories = (history.filter((h) => h.hideEvent !== true));
      if (filteredHistories && filteredHistories.length) {
        this.$nextTick(() => {
          this.showWellEventsLocal = true;
        });
      } else {
        this.$nextTick(() => {
          this.showWellEventsLocal = false;
        });
      }
	    });

    await this.fetchWellSignals();
  }

@Watch('showWellEventsLocal')
  onUpdateShowWellEvents(data) {
    console.log(this.localHistoryEvent);
    if (this.$refs.myChildRefProd as TasqLightningProductionChart && !this.isHistorySectionOpen && this.localHistoryEvent) {
      tasqProductionDataChartModule.setShowWellEvents(data);
      (this.$refs.myChildRefProd as TasqLightningProductionChart).addHistoryToChart(data);
    } else {
      tasqProductionDataChartModule.setShowWellEvents(data);
      this.localHistoryEvent = true;
      // (this.$refs.myChildRefProd as TasqLightningProductionChart).addHistoryToChart(data);
    }
  }
}
